import React, { useState, useEffect } from 'react';
import DefaulLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import { Container } from 'semantic-ui-react';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import moment from 'moment';

const ComplementoPago = (props) => {
  const service = feathers.service('complemento-pago');

  // States
  const [openPanel, setOpenPanel] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  const [currentPago, setCurrentPago] = useState({});
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [query, setQuery] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [bankInformation, setBankInformation] = useState({});

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  
  // OBTENER INFORMACION PARA LA TABLA
  const getData = async () => {
    // console.log('Informacion del query desde el componente complementoDePago getData: ', query);
    if (query) {
      let data = [], result = [];

      try {
        setIsBusy(true);
        setSelection([]);
        const { CtaOrdenante, NomBancoOrdExt } = query.query.ReceptorNombre;
        setBankInformation({CtaOrdenante, NomBancoOrdExt});

        let info = {
          idSucursal: query.query.idSucursal,
          idSocioNegocio: parseInt(query.query.ReceptorNombre.id),
          FechaMax: query.query.FechaMax,
        }

        let result = await service.get({
          idSucursal: query.query.idSucursal,
          idSocioNegocio: parseInt(query.query.ReceptorNombre.id),
          FechaMax: query.query.FechaMax,
        });

        // console.log( 'Estos son los complementos de pago: ', result[0] );

        result[0].sort((a, b) => {
          // Convertir las fechas a objetos Date y compararlas
          return new Date(a.FechaDocumento) - new Date(b.FechaDocumento);
        });

        for (let item of result[0]) {
          item.MontoPago = item.SaldoActual;
        }

        data = data.concat(result[0]);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get results',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
        // console.log('Informacion de la data enviada: ', data);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  const onClose = (isSuccess) => {
    setCurrentPago({});
    setOpenPanel(!openPanel);

    isSuccess && getData();
  };

  const openDrawer = (data) => {
    setCurrentPago(data);
    setOpenPanel(true);
  };

  // Handles
  const handledEditClick = (data) => {
    openDrawer(data);
  };

  const handledDeleteClick = async () => {
    let success = false;

    try {
      const total = selection.length;
      let msg = `Do you want to delete ${total} Client${
        (total > 1 && 's') || ''
      }?`;
      let confirm = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirm) {
        let ids = selection.map((item) => item._id);
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await service.remove(ids);
        success = true;
      }
    } catch (error) {
      console.error(error);
      await swal('Error', `Clients not deleted`, 'error');
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        setSelection([]);
        getData();
      }
    }
  };

  return (
    <DefaulLayout
      currentPath={props.match.path}
      // globalActions={
      //   <TopNavigation openPanel={openPanel} setOpenPanel={setOpenPanel} />
      // }
    >
      <HeaderBG />
      <Container className="fluid-95">
        <SectionTitle>Complemento de Pago</SectionTitle>

        <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

        <TableNavigation
          openPanel={openPanel}
          setOpenPanel={setOpenPanel}
          isBusy={isBusy}
          hasSelection={selection}
          messageAction={messageAction}
          onDeleteClick={handledDeleteClick}
        />
        <Table
          data={data}
          onEditClick={handledEditClick}
          getData={getData}
          setSelection={setSelection}
        />
      </Container>

      {(openPanel && (
        <Drawer
          openPanel={openPanel}
          onClose={onClose}
          dataPago={currentPago}
          service={service}
          hasSelection={selection}
          bankInformation = {bankInformation}
        />
      )) ||
        null}
    </DefaulLayout>
  );
};

export default ComplementoPago;
