import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Drawer from './Drawer';
import { Container,Breadcrumb } from 'semantic-ui-react';
import Table from '../Ubicaciones/Table';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import Filters from './Filters';
import TopNavigation from './TopNavigation';

const Ubicaciones = (props) => {
  const service = feathers.service('ubicaciones');
  const auth = useAuth();

  let [query, setQuery] = useState({
    RFC: { $like: '%%' },
    Organizacion: auth.user.client ? auth.user.client._id : null,
  });
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentUbicacion, setCurrentUbicacion] = useState({});
  const [ubicacion, setUbicacion] = useState(null);

  useEffect(() => {
    const filter = () => {
      if (!query)
        query = { RFC: { $like: '%%' }, Organizacion: auth.user.client._id };
      getData();
    };

    filter();
  }, [query]);

  useEffect(() => {
    // console.debug('auth', JSON.stringify(auth, null, 2));
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setUbicacion(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  const handleSaveBulkData = async (results) => {
    let result = await service.find({
      query: {
        $limit: 1,
        $sort: {
          id: -1
        }
      },
    });
    let id = result.data[0].id;
    let dataMap = results.validData.map(element=>{
      element.Organizacion = auth.user.client._id;
      id++
      element.id = id
      return element
    })
    console.log(dataMap)
    let resultCreate = await service.create(dataMap);
    swal('Guardado', '¡Se ha guardado correctamente!', 'success');  
  }
  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const openDrawer = (ubicacion) => {
    ubicacion.Organizacion = auth.user.client._id;
    if (!ubicacion.id) ubicacion.id = 0;
    setCurrentUbicacion(ubicacion);
    setOpenPanel(true);
  };
  const handleClose = (isSuccess) => {
    setCurrentUbicacion({});
    setOpenPanel(false);

    isSuccess && getData();
  };
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        if (query.Organizacion != '') {
          do {
            result = await service.find({
              query: {
                ...query,
                $skip: data.length,
              },
            });
            data = data.concat(result.data);
          } while (data.length !== result.total);
        }
      } catch (err) {
        console.error('Error', err);
        data = [];
        swal({
          title: 'Error',
          text: 'Failed get ubicaciones',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };
  const handleEditClick = (ubicacion) => {
    openDrawer(ubicacion);
  };
  const handleDeleteClick = async (data) => {
    let confirmation = await swal('Do you want delete this item?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      try {
        await service.remove(data.id);
        swal('Borrado', '¡Se ha borrado correctamente!', 'success');
      } catch (exception) {
        swal('Error', 'Dont delete transporte', 'error');
      }
      getData();
    }
  };
  
  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
            onSaveBulkData={handleSaveBulkData}
          />
        }
      >
        <HeaderBG />
        <Container className="fluid-95">
        <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Carta Porte', content: 'Carta Porte', link: false },
              {
                key: 'BillOfLadingMx',
                content: 'Ubicaciones',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            getData={getData}
          />
          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          ></Table>
        </Container>
      </DefaultLayout>
      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentUbicacion={currentUbicacion}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Ubicaciones;
