import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Icon, Segment } from 'semantic-ui-react';
import feathers from '../../../services/feathers';
import swal from 'sweetalert';

const ActionsComplement = ({ onClose, complemento, handledOnClose }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoding] = useState(false);
  const serviceDocPagos = feathers.service('documentos-pagos');
  const serviceDocPagosImpuestos = feathers.service('documentos-pagos-impuestos');
  const serviceDocumento = feathers.service('documentos');
  const { impuesto, documentoPago, factura, tipoDeCambio } = complemento;

  useEffect(() => {
    if (complemento && complemento.complementos?.length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [complemento]);

  const handleSave = async () => {
    try {
      setLoding(true);

      if( tipoDeCambio ){
        factura.TipoDeCambio = parseFloat( tipoDeCambio );
        await serviceDocumento.patch( factura.id, factura );
      }

      await serviceDocPagosImpuestos.remove(factura.id);
      await serviceDocPagos.remove(factura.id);

      for (let docPago of documentoPago) {
        await serviceDocPagos.create(docPago);
      }

      for (let imp of impuesto) {
        await serviceDocPagosImpuestos.create(imp);
      }

      setLoding(false);
      onClose();

      swal('Editado', '¡Se ha editado correctamente!', 'success');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Segment>
      <ActionContainer>
        <Button
          primary
          compact
          type="button"
          onClick={handleSave}
          disabled={disabledButton}
          loading={loading}
        >
          <Icon name="bell" /> Editar y guardar
        </Button>

        <Button compact onMouseDown={handledOnClose}>
          <Icon name="reply" /> Back
        </Button>
      </ActionContainer>
    </Segment>
  );
};

export default ActionsComplement;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;
