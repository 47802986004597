import React, { useState, useEffect } from 'react';
import {
  Form,
  Segment,
  Header,
  Grid,
  Divider,
  Label,
  Button,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import feathers from '../../../services/feathers';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import TableComplement from '../TableComplement';
import moment from 'moment';

const FormComplement = ({ formik, setComplemento }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [idFormaPago, setIdFormaPago] = useState('');
  const [ordenante, setOrdenante] = useState('');
  const [nomBank, setNomBank] = useState('');
  const [numOperacion, setNumOperacion] = useState('');
  const [monedaDR, setMonedaDR] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [tipoDeCambio, setTipoDeCambio] = useState(0);

  const serviceDocumentosPago = feathers.service('documentos-pagos');
  const serviceDocumentosImpuestos = feathers.service(
    'documentos-conceptos-impuestos'
  );
  const serviceDocumento = feathers.service('documentos');

  useEffect(() => {
    getDocumentosPago();
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [
    startDate,
    idFormaPago,
    ordenante,
    nomBank,
    numOperacion,
    monedaDR,
    tipoCambio,
    selection,
    tipoDeCambio,
  ]);

  const getDocumentosPago = async () => {
    const { values } = formik;

    try {
      const response = await serviceDocumentosPago.find({
        query: {
          id: values.id,
        },
      });

      const documento = await serviceDocumento.find({
        query: { id: values.id },
      });

      setOrdenante(response.data[0].CtaOrdenante);
      setNomBank(response.data[0].NomBancoOrdExt);
      setNumOperacion(response.data[0].NumOperacion);
      setMonedaDR(response.data[0].MonedaDR);
      setIdFormaPago(response.data[0].idFormaPago);

      response.data[0].MonedaDR !== 'MXN'
        ? setTipoDeCambio(documento.data[0].TipoDeCambio)
        : setTipoDeCambio(0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    const { values } = formik;

    try {
      let dataDocumento;
      let dataPagoImpuestos = {};
      let bank = [];
      dataDocumento = values;

      //DOCUMENTOS CONECEPTOS
      let dataDocumentoConcepto = {};
      dataDocumentoConcepto = { ...dataDocumento.conceptos };

      let count = 0;

      //INFORMACION BANCARIA
      for (let select of selection) {
        let info = {};

        info.idFormaPago = idFormaPago;
        info.Total = parseFloat(select.MontoPago);
        info.ImpSaldoAnt = select.SaldoActual;
        info.ImpPagado = parseFloat(select.MontoPago);
        info.ImpSaldoInsoluto = info.ImpSaldoAnt - info.ImpPagado;
        info.PagosTotalFactura = select.SaldoActual;
        info.PagosImportePagado = parseFloat(select.MontoPago);
        info.MetodoPagoDR = 'PPD';
        info.MonedaDR = monedaDR;
        info.idUser = select.idSocioNegocio;
        info.ref_Serie = select.Serie;
        info.ref_Folio = select.Folio;
        info.ref_uuid = select.timbre_uuid;
        info.ref_id = parseInt(select.id);
        info.id = dataDocumento.id;
        info.idRow = ++count;
        info.NumParcialidad = select.NumParcialidad ? select.NumParcialidad : 1;
        info.FechaPago = moment(startDate)
          .clone()
          .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
        info.Fecha_Log = moment(startDate)
          .clone()
          .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
        info.CtaOrdenante = ordenante;
        info.NomBancoOrdExt = nomBank;
        info.NumOperacion = numOperacion;
        bank.push(info);
      }

      let contador = 0;
      let taxes = [];
      for (let data of selection) {
        let resultDataConceptoImpuestos = await serviceDocumentosImpuestos.find(
          {
            query: {
              id: data.id,
            },
          }
        );

        if (resultDataConceptoImpuestos.data) {
          let idRowImpuesto = 1;
          let idsVistos = [];

          for (let impuesto of resultDataConceptoImpuestos.data) {
            resultDataConceptoImpuestos = impuesto; // Verificamos si el idImpuesto ya ha sido visto

            if (idsVistos.includes(impuesto.id)) {
              idRowImpuesto++;
            } else {
              idRowImpuesto = 1; // Reiniciamos a 1 si es un id nuevo
            }

            idsVistos.push(impuesto.id); // Agregamos el idImpuesto a la lista de vistos

            let pagoImpuesto = {
              id: parseInt(dataDocumento.id),
              idRow: ++contador,
              idRowImpuesto: idRowImpuesto,
              CodigoSAT: resultDataConceptoImpuestos.CodigoSAT,
              TasaOCuota: resultDataConceptoImpuestos.TasaOCuota,
              Importe: resultDataConceptoImpuestos.Importe,
              ImporteBase: resultDataConceptoImpuestos.ImporteBase,
              idImpuesto: 1,
              idTipoTax: resultDataConceptoImpuestos.idTipoTax,
              Valor: resultDataConceptoImpuestos.Valor,
            };

            taxes.push(pagoImpuesto);
          }
        }
      }

      setComplemento({
        factura: { ...dataDocumento },
        conceptos: dataDocumentoConcepto,
        complementos: selection,
        documentoPago: bank,
        impuesto: taxes,
        tipoDeCambio,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Segment>
        <Header> Información bancaria </Header>
        <Form>
          <Form.Group>
            <Form.Field>
              <label> Total </label>
              <Label> {total} </Label>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={'equal'}>
            <div className="field">
              <label>
                <b>Fecha Pago*</b>
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  // dataPago.FechaPago = startDate;
                }}
                timeInputLabel="Hora:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
              />
            </div>
            <DropdownSearchFetch
              fluid
              selection
              label="Forma de Pago *"
              value={idFormaPago}
              onChange={(e, { value }) => {
                setIdFormaPago(value);
                // dataPago.idFormaPago = value;
              }}
              placeholder="Forma de Pago"
              featherService="catalogo-forma-pago"
              columName="Descripcion"
              columValue="c_FormaPago"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_FormaPago']} - ${value['Descripcion']}`,
                  value: value['c_FormaPago'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Moneda *"
              value={monedaDR}
              onChange={(e, { value }) => {
                setMonedaDR(value);
                value === 'MXN' && setTipoDeCambio(0);
              }}
              placeholder="Moneda"
              featherService="catalogo-moneda"
              columName="Descripcion"
              columValue="c_Moneda"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Moneda']} - ${value['Descripcion']}`,
                  value: value['c_Moneda'],
                };
              }}
            />
            {monedaDR !== 'MXN' && (
              <Form.Input
                type="decimal"
                placeholder="Tipo de Cambio"
                label="Tipo de Cambio *"
                name="TipoDeCambio"
                value={tipoDeCambio}
                onChange={(e, { value }) => {
                  setTipoDeCambio(value);
                }}
                min={0}
              />
            )}
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Nombre Banco"
              value={nomBank}
              onChange={(e, { value }) => {
                setNomBank(value);
              }}
              placeholder="Nombre Banco"
              featherService="catalogo-banco"
              columName="Descripcion"
              columValue="c_Banco"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Banco']} - ${value['Descripcion']}`,
                  value: value['c_Banco'],
                };
              }}
            />
            <Form.Input
              placeholder="Número Operación"
              label="Número Operación"
              name="NumOperacion"
              value={numOperacion}
              onChange={(e, { value }) => {
                setNumOperacion(value);
              }}
            />
            <Form.Input
              placeholder="Número Cuenta / Cheque"
              label="Número Cuenta / Cheque"
              name="CtaOrdenante"
              value={ordenante}
              onChange={(e, { value }) => {
                value = value.trim();
                value = value.replace(/\s+/g, '');
                setOrdenante(value);
              }}
            />
          </Form.Group>
        </Form>

        <TableComplement
          formik={formik}
          setTotal={setTotal}
          setTipoCambio={setTipoCambio}
          setSelection={setSelection}
        />
      </Segment>
    </>
  );
};

export default FormComplement;
