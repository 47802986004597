import React, { forwardRef, useState, useEffect } from 'react';
import { Form, Segment, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import Dropdown from '../../../components/Formik/Dropdown';
import DatePicker from 'react-datepicker';
import { Icon, Label } from 'semantic-ui-react';
import feathers from '../../../services/feathers';

const FormHeader = ({ formik, setIsCartaPorte, isEdit, isCartaPorte }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (!isEdit) {
      setFieldValue('FechaDocumentoOriginal', values.FechaDocumento);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit) {
      setFieldValue('FechaDocumento', values.FechaDocumentoOriginal);
    }
  }, [isEdit]);

  const handleFormSubmit = (data) => {
    const currentDate = new Date();
    currentDate.setHours(startDate.getHours());
    currentDate.setMinutes(startDate.getMinutes());
    data.FechaDocumento = currentDate;
  };

  const posFoliosService = feathers.service('pos-folios');

  useEffect(() => {
    const getFolios = async () => {
      if (values.idPOS) {
        let POSFolio = await getPOSFolios(
          values.idPOS,
          values.idTipoComprobante
        );

        if (POSFolio) {
          setFieldValue('Serie', !isEdit ? POSFolio.Serie : values.Serie);
          setFieldValue('Folio', !isEdit ? null : values.Folio);
        } else {
          setFieldValue('Serie', '');
          setFieldValue('Folio', '');
        }
      }
    };
    getFolios();
    if (!isEdit) {
      setFieldValue('idCCP', generaUUID_CCC());
    }
  }, []);

  const getPOSFolios = async (idPOS, tipoComprobante) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: idPOS,
        idTipoComprobante: tipoComprobante,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  const generaUUID_CCC = () => {
    const generatedUuid = uuidv4();
    const uuidStr = generatedUuid.toString();
    const uuidWithC = 'CCC' + uuidStr.substring(3).toUpperCase();
    return uuidWithC;
  };

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <DropdownSimpleFetch
            clearable
            fluid
            selection
            label="Tipo de Comprobante *"
            value={values.idTipoComprobante}
            onChange={async (e, { value }) => {
              setFieldValue('idTipoComprobante', value);
              if (values.idPOS) {
                let POSFolio = await getPOSFolios(values.idPOS, value);
                if (POSFolio) {
                  setFieldValue('Serie', POSFolio.Serie);
                  setFieldValue('Folio', null);
                } else {
                  setFieldValue('Serie', '');
                  setFieldValue('Folio', '');
                }
              }
            }}
            placeholder="Tipo de Comprobante"
            featherService="catalogo-tipo-de-comprobante"
            columName="Descripcion"
            columValue="c_TipoDeComprobante"
            error={
              errors.idTipoComprobante &&
              touched.idTipoComprobante && {
                content: errors.idTipoComprobante,
              }
            }
            isCartaPorte={isCartaPorte}
            disabled={values.idTipoComprobante === 'P' ? true : false}
          />

          <Form.Field>
            <label>Serie *</label>
            <Label>{values.Serie}</Label>
          </Form.Field>
          <Form.Field>
            <label>Folio *</label>
            <Label>{values.Folio}</Label>
          </Form.Field>
          {isCartaPorte && (
            <Form.Field>
              <label>Versión Carta Porte</label>
              <Label>3.1</Label>
            </Form.Field>
          )}
          <div className="field">
            <label>
              <b>Fecha Documento *</b>
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                const newDate = moment(date).set({
                  hour: new Date().getHours(),
                  minute: new Date().getMinutes(),
                });
                setStartDate(newDate.toDate());
                setFieldValue('FechaDocumento', newDate);
              }}
              timeInputLabel="Hora:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              name="FechaDocumento"
              value={moment(values.FechaDocumento).format('YYYY-MM-DD h:mm a')}
              onBlur={handleBlur}
              onClick={() => {}}
              error={
                errors.FechaDocumento &&
                touched.FechaDocumento && { content: errors.FechaDocumento }
              }
              disabled={values.idTipoComprobante === 'P' ? true : false}
            />
          </div>
          <DropdownSearchFetch
            fluid
            selection
            label="Moneda *"
            value={values.idMoneda}
            onChange={(e, { value }) => setFieldValue('idMoneda', value)}
            placeholder="Moneda"
            featherService="catalogo-moneda"
            columName="Descripcion"
            columValue="c_Moneda"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_Moneda']} - ${value['Descripcion']}`,
                value: value['c_Moneda'],
              };
            }}
            disabled={values.idTipoComprobante === 'P' ? true : false}
            error={
              errors.idMoneda &&
              touched.idMoneda && { content: errors.idMoneda }
            }
          />
          {values.idTipoComprobante === 'I' && values.idMoneda !== 'MXN' ? (
            <Form.Input
              type="decimal"
              min={0}
              placeholder="TipoDeCambio"
              label="TipoDeCambio *"
              name="TipoDeCambio"
              value={
                values.idMoneda === 'MXN'
                  ? (values.TipoDeCambio = 1.0)
                  : values.TipoDeCambio
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.TipoDeCambio &&
                touched.TipoDeCambio && { content: errors.TipoDeCambio }
              }
            />
          ) : null}
        </Form.Group>

        {values.idTipoComprobante !== 'P' && (
          <>
            <Form.Group widths={'equal'}>
              <Form.Field>
                <label>Carta Porte</label>
                <Checkbox
                  // disabled
                  defaultChecked={isCartaPorte}
                  name={'CartaPorte'}
                  toggle
                  onChange={(e, { checked }) => {
                    setIsCartaPorte(checked);
                    setFieldValue('CartaPorte', checked.toString());
                    setFieldValue(
                      'ubicaciones[0].CartaPorte',
                      checked.toString()
                    );
                    setFieldValue(
                      'ubicaciones[1].CartaPorte',
                      checked.toString()
                    );
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.CartaPorte &&
                    touched.CartaPorte && { content: errors.CartaPorte }
                  }
                />
              </Form.Field>
              {isCartaPorte && (
                <>
                  <Dropdown
                    fluid
                    selection
                    label="Tipo de Viaje *"
                    value={values.TipoViaje}
                    onChange={(e, { value }) =>
                      setFieldValue('TipoViaje', value)
                    }
                    placeholder="Tipo de Viaje"
                    options={[
                      {
                        key: 'Internacional',
                        value: 'Internacional',
                        text: 'Internacional',
                      },
                      { key: 'Nacional', value: 'Nacional', text: 'Nacional' },
                    ]}
                  />
                  {values.TipoViaje !== 'Nacional' && (
                    <>
                      <DropdownSearchFetch
                        fluid
                        selection
                        label="Regimen Aduanero *"
                        value={values.RegimenAduanero}
                        onChange={(e, { value }) =>
                          setFieldValue('RegimenAduanero', value)
                        }
                        placeholder="Regimen Aduanero"
                        featherService="catalogo-regimen-aduanero"
                        columName="Descripcion"
                        columValue="c_ClaveRegimenAduanero"
                        mapValues={(value) => {
                          return {
                            key: Math.random(),
                            text: `${value['c_ClaveRegimenAduanero']} - ${value['Descripcion']}`,
                            value: value['c_ClaveRegimenAduanero'],
                          };
                        }}
                        disabled={
                          values.idTipoComprobante === 'T' ? true : false
                        }
                        error={
                          errors.RegimenAduanero && {
                            content: errors.RegimenAduanero,
                          }
                        }
                        //error={ errors.ReceptorNombre && touched.ReceptorNombre && {content: errors.ReceptorNombre,}}
                      />
                      <Dropdown
                        fluid
                        selection
                        label="Entrada/Salida *"
                        value={
                          values.EntradaSalidaMerc !== undefined
                            ? values.EntradaSalidaMerc
                              ? (values.EntradaSalidaMerc = 1)
                              : (values.EntradaSalidaMerc = 0)
                            : (values.EntradaSalidaMerc = 1)
                        }
                        onChange={(e, { value }) =>
                          setFieldValue('EntradaSalidaMerc', value)
                        }
                        placeholder="Entrada/Salida"
                        options={[
                          { key: 1, value: 1, text: 'Entrada' },
                          { key: 0, value: 0, text: 'Salida' },
                        ]}
                      />
                      <DropdownSearchFetch
                        fluid
                        selection
                        label="País *"
                        value={values.PaisOrigenDestino}
                        onChange={(e, { value }) => {
                          setFieldValue('PaisOrigenDestino', value);
                        }}
                        placeholder="País"
                        featherService="catalogo-pais"
                        columName="Descripcion"
                        columValue="c_Pais"
                        mapValues={(value) => {
                          return {
                            key: Math.random(),
                            text: `${value['c_Pais']} - ${value['Descripcion']}`,
                            value: value['c_Pais'],
                          };
                        }}
                        error={
                          errors.PaisOrigenDestino && {
                            content: errors.PaisOrigenDestino,
                          }
                        }
                      />
                    </>
                  )}
                  <Form.Input
                    placeholder="idCCP"
                    label="idCCP *"
                    name="idCCP"
                    value={values.idCCP}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly
                    error={
                      errors.idCCP && touched.idCCP && { content: errors.idCCP }
                    }
                  />
                </>
              )}
            </Form.Group>
            <Form.Group widths={'equal'}>
              <Form.Input
                placeholder="Comentarios"
                label="Comentarios"
                name="Comentarios"
                value={values.Comentarios}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.Comentarios &&
                  touched.Comentarios && { content: errors.Comentarios }
                }
              />
            </Form.Group>
          </>
        )}
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
